import React from "react"

// Styles
import styles from "./ReportRailpoint.module.scss"

// Components
import TextField from "@material-ui/core/TextField"

export default function ReportRailpoint({ type, onButtonClick, pk }) {
  return (
    <div className={styles.reportContainer}>
        <div className={styles.reportTitle}> Add Note </div>
        <>
          <div className={styles.formContainer}>
            <TextField
              fullWidth
              id="standard-multiline-static"
              label="Add Notes"
              multiline
              variant="filled"
              rows={4}
              defaultValue=""
            />
          </div>
        </>

      <div className={styles.buttonContainer}>
        <button onClick={onButtonClick}>  Add Note </button>
      </div>
    </div>
  )
}
