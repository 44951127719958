// // If your reducer isn't defined you probably forgot to export it as an aciton
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'

// Here I'm using an async thunk to send an API request
export const fetchMedia = createAsyncThunk('media/fetchMedia', async (data) => {
  return fetch( process.env.REACT_APP_API_ROOT + '/api/v1/cms/user/media-manager/', {
    headers: {
      Authorization: localStorage.token
    }
  }).then((res) => res.json())
})

//
export const mediaManagerSlice = createSlice({
  // Name used in aciton types
  name: 'mediaManager',
  // Initial state of the reducerr
  initialState: {
    media: {},
    loading: false,
    loaded: false
  },
  reducers: {},
  // I handle the response using the extra reducers,
  extraReducers: {
    [fetchMedia.pending]: (state) => {
      state.loading = true
    },
    [fetchMedia.fulfilled]: (state, action) => {
      state.loading = false
      state.loaded = true
      state.media = action.payload
    },
    [fetchMedia.rejected]: (state) => {
      state.loading = false
    }

  }
})

export const selectMedia = (state) => state.mediaManager.media

export const selectMediaLoading = (state) => state.mediaManager.loading

export const selectMediaLoaded = (state) => state.mediaManager.loaded

export default mediaManagerSlice.reducer

// Exporting reducer to import it in store.js
