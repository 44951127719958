import React from "react"

import styles from './PageIcons.module.scss'

export default function PageIcons({ icon, text, boldText, moreText }) {
  return (
      <>
        <div className={styles.container}>
          <div className={styles.iconContainer}>
            {icon}
          </div>
          {text} &nbsp;<b>{boldText}</b> &nbsp;{moreText}&nbsp;
          <div className={styles.details}>
            {" "}
            view details
          </div>
        </div>
      </>
  )
}
