import React, { useState, useEffect } from "react"
import { useDispatch, useSelector } from "react-redux"

// Library
import { DateTime } from "luxon"

// Styles
import styles from "./RailpointDetails.module.scss"

// Icons
import ArrowDropDownCircleIcon from "@material-ui/icons/ArrowDropDownCircle"
import PowerSettingsNewIcon from "@material-ui/icons/PowerSettingsNew"
import ReportIcon from "@material-ui/icons/Report"
import NoScreenShot from "../../../icons/NoScreenShot.svg"

// Components
import Button from "@material-ui/core/Button"
import TextField from "@material-ui/core/TextField"
import Dialog from "@material-ui/core/Dialog"
import DialogActions from "@material-ui/core/DialogActions"
import DialogContent from "@material-ui/core/DialogContent"
import DialogContentText from "@material-ui/core/DialogContentText"
import DialogTitle from "@material-ui/core/DialogTitle"
import NoteModal from '../NoteModal/NoteModal';

// Redux
import {
  reloadScreen,
  rebootScreen,
  viewScreenNotes,
  selectActiveScreenNotes,
} from "../../Screens/ScreenManager/ScreenManagerSlice"

//TODO: Clean, rotate reload icon for consistency 


// todo: description name
export default function RailpointDetails({
  image,
  timestamp,
  computerName,
  displayName,
  description,
  station,
  operator,
  text_location,
  screenshot_timestamp,
  pk,
  notes,
  onNoteClick,
}) {
  const dispatch = useDispatch()
  const [screenNotes, setScreenNotes] = useState([])
  const [noteText, setNoteText] = useState("")
  const [open, setOpen] = useState(false)

  const handleClose = () => {
    setOpen(false)
  }
  // const [notes, setnotes] = useState([])

  const handleReboot = () => {
    console.log("handleReboot", pk)
    dispatch(rebootScreen(pk))
  }

  const handleReload = () => {
    //Note, restart === reload
    dispatch(reloadScreen(pk))
    console.log("handleRestart", pk)
  }

  useEffect(() => {
    dispatch(viewScreenNotes(pk))
  }, [dispatch, pk])

  const activeNotes = useSelector(selectActiveScreenNotes)

  useEffect(() => {
    let tempArray = []
    // eslint-disable-next-line
    activeNotes.forEach((x) => tempArray.push(x.note + "," + " "))

    setScreenNotes(tempArray)
  }, [activeNotes])

  const handleTextChange = (e) => {
    setNoteText(e.target.value)
  }

  const handleAddNote = () => {
    let myHeaders = new Headers()
    myHeaders.append("Authorization", localStorage.token)

    let formdata = new FormData()
    formdata.append("note", noteText)

    let requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: formdata,
      redirect: "follow",
    }

    fetch(
      process.env.REACT_APP_API_ROOT + "/api/v1/cms/user/control/add-screen-notee/" + pk,
      requestOptions
    ).then((res) => {
        res.text()
        console.log('response is', res.json())
      })
      .then((result) => {
        console.log('should hit again',result)
      })
      .catch((error) => console.log("error", error))
  }

  return (
    <>
      <div className={styles.container}>
        {image ? (
          <img
            className={styles.screenshot}
            alt={displayName}
            src={"data:image/png;base64," + image}
          />
        ) : (
          <img className={styles.screenshot} alt="No Screenshot available" src={NoScreenShot} />
        )}
        <div className={styles.screenshot}>
          <div className={styles.screenTitle}> {displayName} </div>
          <div className={styles.description}> {description} </div>
          <div className={styles.iconContainer}>
            <>
              <Button className={styles.iconButton} onClick={handleReload}>
                <span>
                  <ArrowDropDownCircleIcon />
                </span>
                <span>Reload</span>
              </Button>
              <Button className={styles.iconButton} onClick={handleReboot}>
                <span>
                  <PowerSettingsNewIcon style={{ color: "#4556FF" }} />
                </span>
                <span>Reboot</span>
              </Button>
              <Button className={styles.iconButton}>
                <span>
                  <ReportIcon disabled style={{ color: "#4556FF" }} />
                </span>
                <span>Ticket</span>
              </Button>
            </>
          </div>

          <div className={styles.detailContainer}>
            <div className={styles.detailTitle}> Computer Name </div>
            <div className={styles.detailText}>
              {" "}
              {computerName ? computerName : "Computer Name not assigned"}{" "}
            </div>

            <div className={styles.detailTitle}> Last Seen </div>
            <div className={styles.detailText}>
              {DateTime.fromISO(timestamp).toLocaleString({
                weekday: "short",
                month: "short",
                day: "2-digit",
                hour: "2-digit",
                minute: "2-digit",
              })}
            </div>

            <div className={styles.detailTitle}> Description </div>
            <div className={styles.detailText}>
              {" "}
              {description ? description : "No description available"}{" "}
            </div>

            <div className={styles.detailTitle}> Station </div>
            <div className={styles.detailText}>
              {" "}
              {station ? station : "Not assigned to a station"}{" "}
            </div>

            <div className={styles.detailTitle}>{" "}Location</div>
              <div className={styles.detailText}>
                {text_location ? text_location : "Location not assigned"}
            </div>

            <div className={styles.detailTitle}> Operator </div>
            <div className={styles.detailText}>
              {" "}
              {operator ? operator : "Not assigned to a operator"}{" "}
            </div>
            <div className={styles.detailTitle}>Notes</div>
            <div className={styles.noteText}>
              {screenNotes ? screenNotes : "No notes assigned to this screen"}
            </div>
          </div>

          <div className={styles.buttonContainer}>
            <NoteModal bonus={() => dispatch(viewScreenNotes(pk))} pk={pk} displayName={displayName}/>
          </div>
          {/* Refactor into it's own component, remove */}
          <Dialog
            open={open}
            onClose={handleClose}
            aria-labelledby="form-dialog-title"
          >
            <DialogTitle id="form-dialog-title">
              Add notes to {displayName}
            </DialogTitle>
            <DialogContent>
              <DialogContentText>
                {/* Add a note to Screen Name, */}
              </DialogContentText>
              <TextField
                autoFocus
                margin="dense"
                id="note"
                label="Note"
                type="text"
                fullWidth
                multiline
                rowsMax={10}
                value={noteText}
                onChange={(e) => handleTextChange(e)}
              />
            </DialogContent>
            <DialogActions>
              <button onClick={handleClose} color="primary">
                Cancel
              </button>
              <button onClick={handleAddNote} color="primary">
                Add Note
              </button>
            </DialogActions>
          </Dialog>
        </div>
      </div>
    </>
  )
}
