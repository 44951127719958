import React from 'react'

// Style
import styles from './Feeds.module.scss'
// import variables from '../../scss/variables.scss'

// Components
import PageHeader from '../../Components/PageHeader/PageHeader'
import PageIcons from '../../Components/PageIcons/PageIcons'
import Card from '../../Components/Card/Card'
import FeedStatus from '../../Components/FeedStatus/FeedStatus'

//Icons
import ErrorIcon from '@material-ui/icons/Error'

function FeedList() {
    const feeds = 
    [
        {
            name: "Darwin",
            online: false,
        },
        {
            name: "ents24",
            online: false,
        },
        {
            name: "LNER",
            online: false,
        },
        {
            name: "TFL",
            online: true,
        },
        {
            name: "Tiger",
            online: false,
        },
        {
            name: "Traveline",
            online: false,
        },
        {
            name: "Wordline",
            online: false,
        }
    ]

    const allFeeds = feeds.map( x => {
        return (
        <>
        <FeedStatus feedName={x.name} status={x.online}/>
        </>
        )
    })

    return (
        <>
         {allFeeds}   
        </>
    )
}

function StationList() {
    const stations = 
    [
        {
            name: "St Pancras",
            online: true,
        },
        {
            name: "Londong Euston",
            online: true,
        },
        {
            name: "marylebone",
            online: true,
        },
    ]

    const allstations = stations.map( x => {
        return (
        <>
        <FeedStatus feedName={x.name} status={x.online}/>
        </>
        )
    })

    return (
        <>
         {allstations}   
        </>
    )
}


export default function Feeds() {

    
    return (
        <>
        <div>
            <PageHeader hideIcons={true} page={"Feeds"}/>
            <PageIcons icon={<ErrorIcon style={{ color: "#FF1053" }}/>} 
            boldText="Worldline" 
            moreText={"heartbeat not detected"}/>
            
            <PageIcons icon={<ErrorIcon style={{ color: "#FF1053" }}/>} 
            boldText="6" 
            moreText={"Feeds not detected"}/>
        </div>
        <div className={styles.cardContainer}>
            <Card title={"Feed Status"} children={<FeedList/>}/>
            {/* {feedList} */}
           
        </div>
        <div className={styles.cardContainer}>
            <Card title={"Stations"} children={<StationList/>}/>
            {/* {feedList} */}
           
        </div>
        </>
    )
}

// Note: fake data here, you 