import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'

export const fetchStations = createAsyncThunk(
    'settings/getStations',
    async () => {
      return fetch(
        process.env.REACT_APP_API_ROOT + `/api/v1/cms/user/all-stations/`, {
          headers: {
            'Authorization' : localStorage.token
          }}
      ).then((res) => res.json())
    }
  )

  export const fetchScreenGroups = createAsyncThunk(
    'settings/getScreenGroups',
    async () => {
      return fetch(
        process.env.REACT_APP_API_ROOT + `/api/v1/cms/user/screen_groups/`, {
          headers: {
            'Authorization' : localStorage.token
          }}
      ).then((res) => res.json())
    }
  )

export const settingsSlice = createSlice({
    name: 'settings',
    initialState: {
        stations: [],
        screenGroups: [],
    },
    reducers: {
    },
    extraReducers: {
        [fetchStations.fulfilled]: (state, action) => {
            state.stations = action.payload
        },
        [fetchScreenGroups.fulfilled]: (state, action) => {
          state.screenGroups = action.payload
        }
    }
})

export const selectStations = (state) => state.settings.stations

export const selectScreenGroups = (state) => state.settings.screenGroups

export default settingsSlice.reducer
