import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'

export const fetchScreenList = createAsyncThunk(
  'screens/getScreens',
  async () => {
    return fetch(
        process.env.REACT_APP_API_ROOT + '/api/v1/cms/user/control/screens-list/', {
        headers: {
          Authorization: localStorage.token
        }
      }
    ).then((res) => res.json())
  }
)

// TODO: Remove, not used , shouldn't store serialisable objects in Redux. Remove side effect too
export const fetchThumbnail = createAsyncThunk(
  'screens/getThumbnails',
  // todo: refactor: pkname isn't actually a name, it's a number
  async (pkname) => {
    return fetch(
      process.env.REACT_APP_API_ROOT + `/api/v1/cms/user/control/get_screenshot/${pkname}`, {
        headers: {
          Authorization: localStorage.token
        }
      }
    ).then((res) => res)
  }
)

export const fetchScreenshot = createAsyncThunk(
  'screens/getScreenshot',
  async (pknum) => {
    return fetch(
      process.env.REACT_APP_API_ROOT + `/api/v1/cms/user/contro/get_screenshot/${pknum}?full_screenshot=True`, {
        headers: {
          Authorization: localStorage.token
        }
      }
    ).then((res) => res)
  }
)

export const rebootScreen = createAsyncThunk(
  'actions/rebootScreens',
  async (pkname) => {
    return fetch(
      process.env.REACT_APP_API_ROOT + `/api/v1/cms/user/screen/${pkname}/reboot`, {
        headers: {
          Authorization: localStorage.token
        }
      }
    ).then((res) => res.json())
  }
)

export const reloadScreen = createAsyncThunk(
  'actions/reloadScreens',
  async (pkname) => {
    return fetch(
      process.env.REACT_APP_API_ROOT + `/api/v1/cms/user/screen/${pkname}/reload`, {
        headers: {
          Authorization: localStorage.token
        }
      }
    ).then((res) => res.json())
  }
)

export const groupScreen = createAsyncThunk(
  'actions/groupScreen',
  async (pkname) => {
    return fetch(
      process.env.REACT_APP_API_ROOT + `/api/v1/cms/user/screen/${pkname}/group`, {
        headers: {
          Authorization: localStorage.token
        }
      }
    ).then((res) => res.json())
  }
)

export const reportScreen = createAsyncThunk(
  'actions/reportScreen',
  async (pkname) => {
    return fetch(
      process.env.REACT_APP_API_ROOT + `/api/v1/cms/user/screen/${pkname}/report`, {
        headers: {
          Authorization: localStorage.token
        }
      }
    ).then((res) => res.json())
  }
)

// Refactor to work
export const addScreenNote = createAsyncThunk(
  'actions/addScreenNote',
  async (pk, data) => {
    return fetch(
      process.env.REACT_APP_API_ROOT + `/api/v1/cms/user/control/add-screen-note/${pk}`, {
        method: 'POST',
        body: JSON.stringify(data),
        headers: {
          Authorization: localStorage.token,
          // "Content-Type": "multipart/form-data"
          "Content-type":"application/x-www-form-urlencoded"
          // 'Content-Type': 'application/json'
        },
      }
    ).then((res) => res.json())
  }
)

export const viewScreenNotes = createAsyncThunk(
  'actions/viewNotes',
  async (pk) => {
    return fetch(
      process.env.REACT_APP_API_ROOT + `/api/v1/cms/user/control/get-screen-notes/${pk}`, {
        headers: {
          Authorization: localStorage.token
        }
      }
    ).then((res) => res.json())
  }
)

export const viewScreenDetails = createAsyncThunk(
  'actions/viewScreebDetails',
  async (pk) => {
    return fetch(
      process.env.REACT_APP_API_ROOT + `/api/v1/cms/user/control/screen-details/${pk}`, {
        headers: {
          Authorization: localStorage.token
        }
      }
    ).then((res) => res.json())
  }
)


export const screenManagerSlice = createSlice({
  name: "screenManager",
  initialState: {
    selectedScreens: [],
    menuVisibility: false,
    screenList: [],
    screenListFilteredByOperator:[],
    screenListFilteredByStation:[],
    screenListFilteredByOperatorAndStation:[],
    searchResults:[],
    searchState: false,
    lastUpdate: null,
    allScreens: [],
    offlineScreens: 0,
    loading: false,
    loaded: false,
    filterState: false,
    filteredData: [],
    filteredVia :{
      operator : false,
      station : false
    },
    numOfActiveFilters: 0,
    activeScreen: {},
    activeScreenNotes : [],
    filterOptions: {
      online: false,
      offline: false,
      disabled: false,
      cis: false,
      fiveMins: false,
      thirtyMins: false,
      dayAgo: false,
    },
    sortOption: 'alphabeticalAsc',
    toDisplay: [],
    rejected: false,
    filterStages :{
      stationFilterApplied: false,
      operatorFilterApplied: false,
      serachViaText: false,
    }
  },
  reducers: {
  addScreen: (state, action) => {
      state.selectedScreens.push(action.payload)
    },
    updateSelected: (state, action) => {
      state.selectedScreens = action.payload
    },
    clearSelected: (state, action) => {
      state.selectedScreens = []
    },
    toggleMenu: (state) => {
      state.menuVisibility = !state.menuVisibility
    },
    sortDescendingTimeOrder: (state) => {
      state.screenList = state.screenList.sort(function (a, b) {
        return new Date(b.screenshot_timestamp) - new Date(a.screenshot_timestamp)
      
      })
    },
    enableFilterState: (state) => {
      state.filterState = true
    },
    disableFilterState: (state) => {
      state.filterState = false
      state.filteredData = []
    },
    setFilteredData: (state, action) => {
      state.filteredData = action.payload
    },
    appendFilterData: (state, action) => {
      state.filteredData.push(action.payload)
    },
    resetFilter: (state) => {
      state.filteredData = []
      state.filterOptions = {
        online: false,
        offline: false,
        disabled: false,
        cis: false,
        fiveMins: false,
        thirtyMins: false,
      }
    },
    setActiveScreen: (state, action) => {
      state.activeScreen = action.payload
    },
    setFilterOptions: (state, action) => {
      state.filterOptions = action.payload
    },
    applyFilter: (state, action) => {
 
      state.filteredData = []
      state.toDisplay = []
      
      if (state.filterOptions.online === true) {
        console.log("yes")

        state.toDisplay.push(
          state.toDisplay.concat(
            state.screenList.filter(
              (e) =>
                new Date() - new Date(e.screenshot_timestamp) <= 60 * 60 * 1000
            )
          )
        )

      }
      if (state.filterOptions.offline === true) {
        // state.toDisplay.concat(state.filteredData.concat(state.screenList.filter(e =>
        state.toDisplay.push(
          state.toDisplay.concat(
            state.screenList.filter(
              (e) =>
                e.screenshot_timestamp === null
            )
          )
        )
        console.log("offline scope")
      }

      if (state.filterOptions.disabled === true)
      {
        console.log('disabled apparrently')
        console.log(state.filterOptions)
        console.log(state.filterOptions.disabled)
      }

      if (state.filterOptions.fiveMins === true)
      {
        console.log('handle 5 mins screens')
        state.toDisplay.push(state.screenList.filter(
          (x) =>
            new Date() - new Date(x.screenshot_timestamp) >= 5 * 60 * 1000 &&
            new Date() - new Date(x.screenshot_timestamp) < 30 * 60 * 1000
        ))
      }

      if (state.filterOptions.thirtyMins === true)
      {
        console.log('handle 30 mins screens')
        state.toDisplay.push(
          state.screenList.filter(
            (x) => 
            new Date() - new Date(x.screenshot_timestamp) >= 30 * 60 * 1000 &&
            x.screenshot_timestamp !== null
            ))
      }
      if (state.filterOptions.dayAgo === true)
      {
        
      }
      if (state.toDisplay !== null) {
        state.filteredData = state.toDisplay.flat()
      }

      state.toDisplay = state.toDisplay.filter(
        (v, i, a) =>
          a.findIndex(
            (t) => t.pk === v.pk && t.computer_name === v.computer_name
          ) === i
      )
      state.filteredData = state.toDisplay.flat()
      console.log(process.env.FAST_REFRESH)
      console.log(process.env.PRODUCTION)
      console.log(process.env)
      // state.filteredData = state.toDisplay.flat()
    },
    changeRejected: ( state ) => {
      state.rejected = false
    },
    emptyScreenList: ( state ) => {
      state.screenList = []
    },
    setSortOption: ( state, action ) => {
      state.sortOption = action.payload
    },
    sortScreenList: ( state ) => {
      
      let sortingData = state.screenList
      
      // ## Sort Alphabetically Ascending ##
      if (state.sortOption === "alphabeticalAsc")
      {
        sortingData.sort((a,b) => (a.display_name ? a.display_name : a.computer_name).localeCompare(b.display_name ? b.display_name : b.computer_name))
        state.screenList = sortingData
      }

      // ## Sort Alphabetically Descending ##
      if (state.sortOption === "alphabeticalDesc")
      {
        sortingData.sort((b,a) => (a.display_name ? a.display_name : a.computer_name).localeCompare(b.display_name ? b.display_name : b.computer_name))
        state.screenList = sortingData
      }

      if (state.sortOption === "oldestFirst")
      {
        let screensWithTimes = sortingData.filter(x => x.screenshot_timestamp !== null)
        let screensWithoutTimes = sortingData.filter(x => x.screenshot_timestamp === null)

        let sortedTimeList = screensWithTimes.sort(function(a, b) {
        return (a.screenshot_timestamp < b.screenshot_timestamp) ? -1 : ((a.screenshot_timestamp > b.screenshot_timestamp) ? 1 : 0);
        });

        state.screenList = sortedTimeList.concat(screensWithoutTimes)
      }

      if (state.sortOption === "newestFirst")
      {
        let screensWithTimes = sortingData.filter(x => x.screenshot_timestamp !== null)
        let screensWithoutTimes = sortingData.filter(x => x.screenshot_timestamp === null)

        let sortedTimeList = screensWithTimes.sort(function(a, b) {
          return (a.screenshot_timestamp > b.screenshot_timestamp) ? -1 : ((a.screenshot_timestamp > b.screenshot_timestamp) ? 1 : 0);
        });

        state.screenList = sortedTimeList.concat(screensWithoutTimes)
      }
    },
    setFilteredViaState: (state, action) => {
      state.filteredVia = action.payload
    },
    setNumOfActiveFilters: (state, action) => {
      state.numOfActiveFilters = action.payload
    },
    // screenListFilteredByOperator:[],
    // screenListFilteredByStation:[],
    // screenListFilteredByOperatorAndStation:[],
    setscreenListFilteredByOperator: ( state, action) => {
      // if (action.payload.type === 'Operator')
      // state.screenListFilteredByOperator = action.payload
      // state.screenListFilteredByStation = action.payload
      // state.screenListFilteredByOperatorAndStation = action.payload
      console.log('Storing Filter by op')
      console.log('Action' + action)
      console.log('Payload' + action.payload)
    },
    setSearchState: (state, action) => {
      state.searchState = (action.payload)
    },
    setSearchResults: (state, action) => {
      state.searchResults = (action.payload)
    }
  },
  extraReducers: {
    [fetchScreenList.pending]: (state) => {
      console.log('screenlist pending')
      state.loading = true
    },
    [fetchScreenList.fulfilled]: (state, action) => {
      state.loading = false
      state.screenList = action.payload
      state.lastUpdate = new Date().toLocaleTimeString()
      if (state.rejected === true) {
        state.loading = false
      }

      let sortingData = action.payload
      
      // ## Sort Alphabetically Ascending ##
      if (state.sortOption === "alphabeticalAsc")
      {
        sortingData.sort((a,b) => (a.display_name ? a.display_name : a.computer_name).localeCompare(b.display_name ? b.display_name : b.computer_name))
        state.screenList = sortingData
      }

      // ## Sort Alphabetically Descending ##
      if (state.sortOption === "alphabeticalDesc")
      {
        sortingData.sort((b,a) => (a.display_name ? a.display_name : a.computer_name).localeCompare(b.display_name ? b.display_name : b.computer_name))
        state.screenList = sortingData
      }

      if (state.sortOption === "oldestFirst")
      {
        let screensWithTimes = sortingData.filter(x => x.screenshot_timestamp !== null)
        let screensWithoutTimes = sortingData.filter(x => x.screenshot_timestamp === null)

        let sortedTimeList = screensWithTimes.sort(function(a, b) {
        return (a.screenshot_timestamp < b.screenshot_timestamp) ? -1 : ((a.screenshot_timestamp > b.screenshot_timestamp) ? 1 : 0);
        });

        state.screenList = sortedTimeList.concat(screensWithoutTimes)
      }

      if (state.sortOption === "newestFirst")
      {
        let screensWithTimes = sortingData.filter(x => x.screenshot_timestamp !== null)
        let screensWithoutTimes = sortingData.filter(x => x.screenshot_timestamp === null)

        let sortedTimeList = screensWithTimes.sort(function(a, b) {
          return (a.screenshot_timestamp > b.screenshot_timestamp) ? -1 : ((a.screenshot_timestamp > b.screenshot_timestamp) ? 1 : 0);
        });

        state.screenList = sortedTimeList.concat(screensWithoutTimes)
      }
      if (state.loaded === false)
      {
        state.loaded = true
      }
    },
    [fetchScreenList.rejected] : (state, action) => {
      console.log('these things actually get rejected?')
      console.log(action)
      console.log(action.payload)
      state.rejected = true
    },
    [fetchThumbnail.fulfilled]: (state, action) => {
      state.loading = false
    },
    [viewScreenDetails.fulfilled]: (state, action) => {
      state.activeScreen = action.payload
    },
    [viewScreenNotes.fulfilled]: (state, action) => {
      state.activeScreenNotes = action.payload
    },
  },
})


export const { sortDescendingTimeOrder, addScreen, removeScreen, toggleScreens, 
  updateSelected, clearSelected, enableFilterState, disableFilterState, filterData, 
  setFilteredData, resetFilter, setFilterOptions, applyFilter, changeRejected,
  emptyScreenList, setSortOption, sortScreenList, setFilteredViaState, setNumOfActiveFilters,
  setscreenListFilteredByOperator, setSearchState, setSearchResults
 } = screenManagerSlice.actions

export const selectAllScreens = (state) => state.screenManager.allScreens

export const selectRejected = (state) => state.screenManager.rejected

export const selectSelectedScreens = (state) => state.screenManager.selectedScreens

export const selectScreenList = (state) => state.screenManager.screenList

export const selectLastUpdate = (state) => state.screenManager.lastUpdate

export const selectFilterState = (state) => state.screenManager.filterState

export const selectFilteredData = (state) => state.screenManager.filteredData

export const selectFilterOptions = (state) => state.screenManager.filterOptions

export const selectNumOfActiveFilters = (state) => state.screenManager.numOfActiveFilters

export const selectFilteredVia = (state) => state.screenManager.filteredVia

export const selectSearchState = (state) => state.screenManager.searchState

export const selectSearchResults = (state) => state.screenManager.searchResults

export const selectActiveScreen = (state) => state.screenManager.activeScreen

export const selectActiveScreenNotes = (state) => state.screenManager.activeScreenNotes

export const selectSortOption = (state) => state.screenManager.sortOption

export const selectLoaded = (state) => state.screenManager.loaded

export const selectActiveFilterStages = (state) => state.screenManager.filterStages
// export const selectFilterState = (state) => state.screenManager.filterState

export default screenManagerSlice.reducer


// 3 States

// Enable Search by Operator state
// Filter results by the selected Operator
// FilteredViaOperator = screenList.filter(x => x.operator === (selectedOp))

// Filter it by station
// If operatorFilterState === true
// FilteredViaStation = FilteredViaStation.filter(x => x.station === (selectedStation))
// else
// filteredViaStation = ScreenList.filter(x => x.station === selectedStations)

// Time to search
// if opState && stationState === true
// {
// tempResults = filteredViaStation.filter(x.all of the things === searchCriteria)
// push(tempByName, tempByOperator, tempByStation)
//}
// else
// {
// 
// }