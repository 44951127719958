import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'

export const fetchScreenDetails = createAsyncThunk('screen/fetchDetails', async (data) => {
    return fetch( process.env.REACT_APP_API_ROOT + `/api/v1/cms/user/screen/862/`, {
        headers: {
            Authorization: localStorage.token
        }
    }).then((res) => res.json())
})

export const screenDetailsSlice = createSlice({
    name: 'screenDetails',
    initialState: {
        screenDetails: {}
    },
    reducers: {},
    extraReducers: {
        [fetchScreenDetails.fulfilled]: (state, action) => {
            state.screenDetails = action.payload
        }
    }
}) 

export const selectScreenDetails = (state) => state.screenDetails.screenDetails

export default screenDetailsSlice.reducer


//todo: Dead Page