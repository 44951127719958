import React, { useState, useEffect, useCallback } from "react"
import { useDispatch, useSelector } from "react-redux"
import { useHistory } from "react-router-dom"

// import PropTypes from 'prop-types'

// Components
import RailPoints from "./../../Components/RailPoints/RailPoints"
import ScreenManagerMenu from "./../../Components/ScreenManagerMenu/ScreenManagerMenu"
import ScreenManagerFilter from "./../../Components/ScreenManagerFilter/ScreenManagerFilter"
import ScreenManagerTable from "./../../Components/ScreenManagerTable/ScreenManagerTable"
import DashboardWelcome from "../../Components/DashboardWelcome/DashboardWelcome"
import PageHeader from "../../Components/PageHeader/PageHeader"
import Modal from "@material-ui/core/Modal"
import ReportRailpoint from "../../Components/ReportRailpoint/ReportRailpoint"
import TextField from '@material-ui/core/TextField'

// Styles
import styles from "./ScreenManager.module.scss"
import { createMuiTheme, MuiThemeProvider } from "@material-ui/core/styles"


// Redux
import {
  fetchScreenList,
  selectScreenList,
  clearSelected,
  selectSelectedScreens,
  selectFilteredData,
  setFilteredData,
  selectRejected,
  selectFilterState, 
  changeRejected,
  emptyScreenList,
  selectSortOption,
  sortScreenList,
  // selectLastUpdate,
  selectSearchState,
  // selectFilteredVia,
  setSearchState,
  setSearchResults,
  selectSearchResults,
} from "./ScreenManagerSlice"

import { openSnackBar, setSnackMessage } from "../../Slices/snackbarSlice"
import { logoutUser } from "../Login/LoginSlice"
//TODO: Clean Up

export default function ScreenManager() {
  const dispatch = useDispatch()
  const history = useHistory()

  // eslint-disable-next-line no-unused-vars
  const [filterVisibility, setFilterVisibility] = useState(false)
  const [screenVisibility, setScreenVisibility] = useState(true)
  const [reportsModalOpen, setReportsModalOpen] = useState(false)
  const [notesModalOpen, setNotesModalOpen] = useState(false)
  const [searchCriteria, setSearchCriteria] = useState('')
  // const [filteredScreens, setFilteredScreens] = useState([])

  // Selectors
  const screenList = useSelector(selectScreenList)
  const selectedScreens = useSelector(selectSelectedScreens)
  const filteredData = useSelector(selectFilteredData)
  const filterState = useSelector(selectFilterState)
  const rejected = useSelector(selectRejected)
  const sortOption = useSelector(selectSortOption)
  // const lastUpdate = useSelector(selectLastUpdate)
  // const filteredVia = useSelector(selectFilteredVia)
  const searchState = useSelector(selectSearchState)
  const searchResults = useSelector(selectSearchResults)

  useEffect(() => {
    if (rejected === true )
    {
      dispatch(logoutUser())
      dispatch(emptyScreenList())
      dispatch(setSnackMessage('Failed to get data'))
      dispatch(openSnackBar())
      dispatch(changeRejected())
      history.push('/')
    }
    }, [rejected, dispatch, history,])

  // Fetch and clear selected screens on load
  useEffect(() => {
    dispatch(fetchScreenList())
    dispatch(clearSelected())
  }, [dispatch])

  // Sort screens into order
  useEffect(() => {
    dispatch(sortScreenList())
  }, [sortOption, dispatch])

  // Grab Updates every 30 seconds
  useEffect(() => {
    const interval = setInterval(() => {
      dispatch(fetchScreenList())
      dispatch(sortScreenList())
      // }, 300000)
    }, 60000)
    return () => clearInterval(interval)
  }, [dispatch])

  // useEffect(() => {
  //   handleSearch('Enter')
  // }, [screenList])

  const getMuiTheme = () =>
    createMuiTheme({
      overrides: {
        MUIDataTable: {
          root: {},
          paper: {
            backgroundColor: "transparent",
            boxShadow: "none",
          },
        },
        MuiToolbar: {
          root: {
            backgroundColor: "transparent",
          },
        },
        MUIDataTableHeadCell: {
          sortActive: {
            color: "#4556FF!important",
          },
        },
        MuiButton: {
          text: {
            color: "#4556FF!important",
          },
        },
        MuiTableHead: {
          root: {
            backgroundColor: "transparent!important",
            color: "#4556FF!important",
          },
        },
        MuiTableCell: {
          head: {
            backgroundColor: "transparent!important",
            color: "#4556FF!important",
          },
        },
        MUIDataTableSelectCell: {
          headerCell: {},
        },
        MuiTableFooter: {
          root: {
            "& .MuiToolbar-root": {
              backgroundColor: "transparent",
            },
          },
        },
      },
    })

  // Filter screens without a screenshot time stamp
  let noScreenShotList = screenList.filter(
    (x) => x.screenshot_timestamp === null
  )

  const handleSearch = useCallback((e) => {
    console.log(e.key)
    let toFilterFrom = []
    if (e.key === 'Enter' && searchCriteria !== '')
    {
      // Need to hydrate store with new data to filter from if filtering multiple levels deep
      (filterState === true ? toFilterFrom = filteredData : toFilterFrom = screenList  )
      // let toFilterFrom = 
      const filteredViaDisplayName = toFilterFrom.filter(x => x.display_name !== null && x.display_name.toLowerCase().includes(searchCriteria.toLowerCase()))
      const filteredViaOperator = toFilterFrom.filter(x => x.operator !== null && x.operator.toLowerCase().includes(searchCriteria.toLowerCase()))
      const filteredViaOperatorCode = toFilterFrom.filter(x => x.operator_code !== null && x.operator_code.toLowerCase().includes(searchCriteria.toLowerCase()))
      const filteredViaComputerName = toFilterFrom.filter(x => x.computer_name !== null && x.computer_name.toLowerCase().includes(searchCriteria.toLowerCase()))
      const fiteredViaStation = toFilterFrom.filter(x => x.station !== null && x.station.toLowerCase().includes(searchCriteria.toLowerCase()))

        let newResults = [...filteredViaDisplayName,...filteredViaOperator,...filteredViaOperatorCode, ...filteredViaDisplayName,
          ...filteredViaComputerName, ...fiteredViaStation]
          
          newResults = [...new Set(newResults)]

      dispatch(setSearchState(true))
      dispatch(setSearchResults(newResults))
    }
    else {
      if (searchState === true && searchCriteria === '')
      {
        dispatch(setSearchState(false))
      }
    }
  },[dispatch, filterState, filteredData, screenList, searchCriteria, searchState])

  useEffect(() => {
    if (searchState === true)
    {
      // Getting search to fire
      handleSearch({key:'Enter'})
    }
  }, [screenList, searchState, handleSearch])

  // Create an array containing screens that haven't been seen for more than 5 minutes
  // but less than 30 minutes as we want to list those seperately
  let fivePlusMins = screenList.filter(
    (x) =>
      new Date() - new Date(x.screenshot_timestamp) >= 10 * 60 * 1000 &&
      new Date() - new Date(x.screenshot_timestamp) < 30 * 60 * 1000
  )

  // Array of screens that haven't been seen for more than 30 mins
  let thirtyPlusMins = screenList.filter(
    (x) =>
      new Date() - new Date(x.screenshot_timestamp) >= 60 * 60 * 1000 &&
      x.screenshot_timestamp !== null
  )

  const handleNoteClick = () => {
    setNotesModalOpen(true)
    console.log("Set Notes")
  }

  const handleReportClick = () => {
    setReportsModalOpen(true)
    console.log("report")
  }

  const handleReportsModalClose = () => {
    setReportsModalOpen(false)
  }

  const handleNotesModalClose = () => {
    setNotesModalOpen(false)
  }

  const handleFive = () => {
    dispatch(setFilteredData(fivePlusMins))
     }

  const handleThirty = () => {
    dispatch(setFilteredData(thirtyPlusMins))
  }

  // const handleWhatToDisplay = () => {
  //   if (filteredByBoth === true && searchState === true)
  //   {
  //     const screensToDisplay = 
  //       <>
  //       Search Results
  //      </>
  //   } 
  //    if (filteredVia.operator === true && filteredVia.operator === true)
  //     {
  //       // Display Filtered By Both        
  //     }
  //     if (filteredVia.station === true)
  //     {
  //       // Display Stations
  //     }
  //     if (filteredVia.operartor === true)
  //     {

  //     }
  //     // if 
  //   // if (filterdByOp === true)
  //   // {
  //   //   // Show filtered by operator
  //   // }
  //   // if (filteredByStation === true)
  //   // {
  //   //   // Show filtered by station
  //   // }
  //   // else {
  //   //   // screenlist.map
  //   // }
    
  // }

  return (
    <>
      <Modal open={reportsModalOpen} onClose={handleReportsModalClose}>
        <div className={styles.modalContainer}>
          <ReportRailpoint
            type={"report"}
            onButtonClick={handleReportsModalClose}
          />
        </div>
      </Modal>
      <Modal open={notesModalOpen} onClose={handleNotesModalClose}>
        <ReportRailpoint onButtonClick={handleNotesModalClose} />
      </Modal>
      <div className={styles.filter}></div>
      <PageHeader
        page="Dashboard"
        iconOneOnClick={() => setScreenVisibility(true)}
        iconOneDisabled={screenVisibility}
        iconTwoOnClick={() => setScreenVisibility(false)}
        iconTwoDisabled={!screenVisibility}
        handleFilterClick={() => setFilterVisibility(!filterVisibility)}
      />

      <div className={styles.welcomeBox}>
        <DashboardWelcome
          attention={noScreenShotList.length}
          grabAttention={() => console.log(noScreenShotList)}
          // grabFive={() => dispatch(setFilteredData(fivePlusMins))}
          grabFive={handleFive}
          fiveMins={fivePlusMins.length}
          grabThirty={handleThirty}
          thirtyMins={thirtyPlusMins.length}
        />
      </div>
      <div className={styles.searchBox}>
        <TextField
          id="standard-basic"
          label="Search"
          fullWidth
          onChange={(e) => setSearchCriteria(e.target.value)}
          onKeyPress={handleSearch}
          // value={searchCriteria}
        />{" "}
      
        {/* Filter State = {JSON.stringify(filterState)}
        <br/>
        Last Update = {JSON.stringify(lastUpdate)}
        <br/>
        Search State = {JSON.stringify(searchState)}
        <br/>
        Filtered via stations = {JSON.stringify(filteredVia.station)}
        <br/>
        Filtered via operators = {JSON.stringify(filteredVia.operator)}
        <br/>
        Filtered via operators and stations = {JSON.stringify(filteredVia.operator + ',' + filteredVia.station)}
        <br/> */}
        {/* Last Update = {(DateTime.fromISO(lastUpdate).toRelative())} */}
      </div>
      <>
        {screenVisibility === false ? (
          <div className={styles.screenManagerTable}>
            <MuiThemeProvider theme={getMuiTheme()}>
              <ScreenManagerTable data={screenList} />
            </MuiThemeProvider>
          </div>
        ) : (
          <>
            <div className={styles.screenManagerContainer}>

            <div className={styles.filter}>
                  {filterVisibility === true && <ScreenManagerFilter />}
                </div>
                
              <div className={styles.screenManager}>
                {/* is the filter state true? */}
                { searchState &&
                  searchResults.map((e,i) => (
                    <>
                        <RailPoints
                        key={e.pk}
                        computerName={e.computer_name}
                        description={e.description}
                        station={e.station}
                        operator={e.operator}
                        text_location={e.text_location}
                        screenname={e.display_name ?? e.computer_name}
                        pk={e.pk}
                        selected={false}
                        timestamp={e.screenshot_timestamp}
                        />
                    </>
                  ))
                }
                
                {filterState  ? 

                  filteredData.length === 0 ? "No screens matching your criteria" :
                  !searchState && 
                  filteredData.map((e, i) => (
                    <>
                    {/* Displays filtered results */}  
                      <RailPoints
                        key={e.pk}
                        computerName={e.computer_name}
                        description={e.description}
                        station={e.station}
                        operator={e.operator}
                        text_location={e.text_location}
                        screenname={e.display_name ?? e.computer_name}
                        pk={e.pk}
                        selected={false}
                        timestamp={e.screenshot_timestamp}
                        />
                    </>
                  ))
                    // ELSE
                  : 
                  // Display the response from the API, unfiltered data should be displayed by default
                  !searchState && 
                    screenList.map((e, i) => (
                      <RailPoints
                        key={e.pk}
                        computerName={e.computer_name}
                        description={e.description}
                        station={e.station}
                        operator={e.operator}
                        text_location={e.text_location}
                        screenname={e.display_name ?? e.computer_name}
                        pk={e.pk}
                        selected={false}
                        timestamp={e.screenshot_timestamp}
                      />
                    ))
                    }
              </div>
            </div>
            {selectedScreens.length >= 1 && 
            <div className={styles.menu}>
                <ScreenManagerMenu
                disabled={selectedScreens.length}
                onNoteClick={handleNoteClick}
                onReportClick={handleReportClick}
                selectedScreens={selectedScreens.length}
                pkList={selectedScreens}
                />
              </div>
              }
          </>
        )}
      </>
    </>
  )
}

// ScreenManager.PropTypes = {

// }
