import { createSlice } from '@reduxjs/toolkit'

export const snackbarSlice = createSlice({
    name:"snackbar",
    initialState: {
        open: false,
        snackMessage: ''
    },
    reducers: {
        openSnackBar: ( state ) => {
            state.open = true
        },
        closeSnackBar: ( state ) => {
            state.open = false
        },
        setSnackMessage: ( state, action ) => {
            state.snackMessage = action.payload
        }
    },
})

export const { openSnackBar, closeSnackBar, setSnackMessage } = snackbarSlice.actions

export const selectOpen = (state) => state.snackbar.open

export const selectSnackMessage = (state) => state.snackbar.snackMessage

export default snackbarSlice.reducer