import React, {useState} from "react";

// Components
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";

// Redux
import { useDispatch } from "react-redux";
import { setSnackMessage, openSnackBar } from "../../Slices/snackbarSlice";


export default function NoteModal( {pk, displayName, bonus}) {
  // States
  const dispatch = useDispatch()
  const [open, setOpen] = useState(false)
  const [noteText, setNoteText] = useState("")

  // Functions
  const handleTextChange = (e) => {
    setNoteText(e.target.value)
  }

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleAddNote = () => {

    let myHeaders = new Headers()
    myHeaders.append("Authorization", localStorage.token)

    let formdata = new FormData()
    formdata.append("note", noteText)

    let requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: formdata,
      redirect: "follow",
    }

    fetch(
      process.env.REACT_APP_API_ROOT + "/api/v1/cms/user/control/add-screen-note/" + pk,
      requestOptions
    )
      .then((response) => {
        bonus()
        dispatch(setSnackMessage('Note Created'))
        dispatch(openSnackBar())
        response.text()}
        )
      .then((result) =>{ 
        handleClose()
        setNoteText('')
      })
      .catch((error) => console.log("error", error))
  }


  return (
    <div>
      <button  onClick={handleClickOpen}>
        Add Note
      </button>

      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="form-dialog-title"
      >
        <DialogTitle id="form-dialog-title">Add Note to {displayName}.</DialogTitle>
        <DialogContent>
          <DialogContentText>
          </DialogContentText>
          <TextField
            autoFocus
            margin="dense"
            id="name"
            label="Note"
            type="email"
            fullWidth
            multiline
            value={noteText}
            onChange={(e) => handleTextChange(e)}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary">
            Cancel
          </Button>
          <Button onClick={handleAddNote} color="primary">
            Add Note
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
