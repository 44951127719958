import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";

// Styles
import styles from "./Login.module.scss";

// Components
import TextField from "@material-ui/core/TextField";
import CircularProgress from "@material-ui/core/CircularProgress";

// Actions
import { fetchLogin, selectIsAuthenticated, selectIsLoading } from "./LoginSlice";

export default function Login() {
    const history = useHistory();
    const [username, setUsername] = useState("");
    const [password, setPassword] = useState("");

    const isAuth = useSelector(selectIsAuthenticated);
    const isLoading = useSelector(selectIsLoading);

    const dispatch = useDispatch();

    const getToken = () => {
        const details = {
            grant_type: "password",
            username,
            password,
            client_id: process.env.REACT_APP_CLIENT,
            client_secret: process.env.REACT_APP_CS,
        };

        const data = new FormData();

        for (const key of Object.keys(details)) {
            details[key] !== null && typeof details[key] === "object" ? data.append(key, JSON.stringify(details[key])) : data.append(key, details[key]);
        }

        dispatch(fetchLogin(data));
        if (isAuth === true) {
            history.push("/Dashboard");
        }
    };

    useEffect(() => {
        if (isAuth === true) {
            history.push("/Dashboard");
        }
    }, [history, isAuth]);

    const handleSubmit = (e) => {
        if (e.key === "Enter") {
            getToken();
        }
    };

    return (
        <div className={styles.background}>
            <div className={styles.loginBox}>
                <div className={styles.loginTitle}>Welcome to Railpoint.</div>
                {isAuth}
                {isLoading === true ? (
                    <div className={styles.progress}>
                        <CircularProgress />
                    </div>
                ) : (
                    <>
                        <div className={styles.formContainer}>
                            <TextField id="username" onChange={(e) => setUsername(e.target.value)} value={username} color="#4455FF" fullWidth label="Username" />
                            <TextField onChange={(e) => setPassword(e.target.value)} onKeyPress={(e) => handleSubmit(e)} value={password} type="password" id="password" fullWidth label="Password" />
                        </div>
                    </>
                )}
                {isLoading === false && (
                    <div className={styles.buttonContainer}>
                        <button onClick={getToken}> Login </button>
                    </div>
                )}
            </div>
        </div>
    );
}

// Actual Login Page
