import React from 'react'

// Styles
import styles from './PageHeader.module.scss'
import '../../scss/variables.scss';

// Components
import Button from '@material-ui/core/Button'

// Icons
import ListIcon from '@material-ui/icons/List'
import CameraIcon from '@material-ui/icons/CameraAlt'
import FilterListIcon from "@material-ui/icons/FilterList"
import Badge from '@material-ui/core/Badge'

// Redux
import { selectNumOfActiveFilters } from '../../Screens/ScreenManager/ScreenManagerSlice';
import { useSelector } from 'react-redux';

export default function PageHeader({page, iconTwo, iconOneOnClick, iconTwoOnClick, iconOneDisabled, iconTwoDisabled, hideIcons, handleFilterClick}) {
    const activeColour = '#FE9000'
    const numOfActiveFilters = useSelector(selectNumOfActiveFilters)

    return (
        <>
        <div className={styles.headingBox}>
        <div className={styles.pageTitle}>
          {page}
        </div>
        {hideIcons !== true && 
        <div className={styles.toolbarContainer}>
          <Button className={styles.toolbarButton} disabled={iconOneDisabled} onClick={iconOneOnClick}><span><CameraIcon color={activeColour}  /></span><span>Screens</span></Button>
          <Button className={styles.toolbarButton} disabled={iconTwoDisabled} onClick={iconTwoOnClick}><span>{<ListIcon/>}</span><span>List</span></Button>
          
          <Badge badgeContent={numOfActiveFilters} color="secondary">
          <Button className={styles.filterButton} onClick={handleFilterClick} ><span>{
            <FilterListIcon/>
          }</span><span>Filter</span></Button>
          </Badge>
        </div>
        }
      </div>    
        </>
    )
}

