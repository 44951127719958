// If your reducer isn't defined you probably forgot to export it as an aciton
import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";

export const fetchLogin = createAsyncThunk("login/Login", async (data) => {
    return fetch(process.env.REACT_APP_API_ROOT + "/control/internal/o/token/", {
        method: "POST",
        body: data,
        headers: {
            Authorization: "Bearer XMcu5oozPqQ8VhAT91B84OKDdIlkSi",
        },
    })
    .then((res) => res.json())
    .catch((error) => error.json());
});

export const loginSlice = createSlice({
    name: "login",
    initialState: {
        isAuthenticated: false,
        access_token: null,
        loading: false,
        // open: false,
    },
    reducers: {
        logoutUser: (state) => {
            state.isAuthenticated = false;
            localStorage.removeItem("token");
        },
    },
    extraReducers: {
        [fetchLogin.pending]: (state, action) => {
            state.loading = true;
        },
        [fetchLogin.fulfilled]: (state, action) => {
            state.loading = false;
            if (action.payload.error) {
                state.isAuthenticated = false;
            } else {
                state.username = "Richard";
                state.isAuthenticated = true;
            }
            // action.payload.error ? console.log('errror') : console.log('no error')
            // state.isAuthenticated = true
            // eslint-disable-next-line no-useless-concat
            state.access_token = "Bearer" + " " + action.payload.access_token;
            // eslint-disable-next-line no-useless-concat
            localStorage.setItem("token", "Bearer" + " " + action.payload.access_token);
        },
        [fetchLogin.rejected]: (state, action) => {
            state.isAuthenticated = false;
            state.loading = false;
        },
    },
});

export const { logoutUser, confirmAuth } = loginSlice.actions;
export const username = (state) => state.login.username;
export const selectIsLoading = (state) => state.login.loading;
export const selectIsAuthenticated = (state) => state.login.isAuthenticated;
export const selectAccessToken = (state) => state.login.access_token;
export default loginSlice.reducer;
