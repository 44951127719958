import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import {
  useParams, Link
} from "react-router-dom";

// Styles
import styles from './ScreenDetails.module.scss'

// Icons
import ArrowDropDownCircleIcon from '@material-ui/icons/ArrowDropDownCircle'
import PowerSettingsNewIcon from '@material-ui/icons/PowerSettingsNew'
import ReportIcon from '@material-ui/icons/Report'

// Components
import IconButton from '@material-ui/core/IconButton'
import Dialog from '@material-ui/core/Dialog'
import NoteModal from '../../Components/NoteModal/NoteModal'
// Redux

import { viewScreenDetails, viewScreenNotes, selectActiveScreen, selectActiveScreenNotes } from '../ScreenManager/ScreenManagerSlice';




export default function ScreenDetails () {
  let { pknum } = useParams();
  const dispatch = useDispatch()
  
  const activeScreen = useSelector(selectActiveScreen)
  const activeNotes = useSelector(selectActiveScreenNotes)

  const [screenNotes, setScreenNotes] = useState([])
  const [thumb, setThumb] = useState("")
  const [fullScreenshot, setFullScreenshot] = useState("")
  const [open, setOpen] = useState(false) 
 
  useEffect(() => {
    dispatch(viewScreenDetails(pknum))
    dispatch(viewScreenNotes(pknum))
  }, [dispatch, pknum])

  useEffect(() => {
    let tempArray = []
    activeNotes.forEach(x => 
      // eslint-disable-next-line
      tempArray.push(x.note + ',' + ' '))

      setScreenNotes(tempArray)
  }, [activeNotes])

  useEffect(() => {
    const grabThumbnail = () => {
      fetch(
        process.env.REACT_APP_API_ROOT +
          `/api/v1/cms/user/control/get_screenshot/${pknum}`,
        {
          headers: {
            Authorization: localStorage.token,
          },
        }
      )
        .then((res) => res.json())
        .then((res) => {
          setThumb(res)
        })
        .catch((error) => {
          // setLoading(false)
        })
      // setLoading(false)
    }

    grabThumbnail()

    const grabFullScreenshot = () => {
      fetch(
        process.env.REACT_APP_API_ROOT +
          `/api/v1/cms/user/control/get_screenshot/${pknum}?full_screenshot=True`,
        {
          headers: {
            Authorization: localStorage.token,
          },
        }
      )
        .then((res) => res.json())
        .then((res) => {
          setFullScreenshot(res)
        })
        .catch((error) => {
          // setLoading(false)
        })
    }

    grabFullScreenshot()
  }, [pknum, activeScreen])


  const handleReload = (pk) => {
    console.log('Handling Reload' + pk)
  }
  
  const handleReboot = (pk) => {
    console.log('Handling Reboot' + pk)
    
  }
  
  const handleTicket = (pk) => {
    console.log('Handling Report' + pk)

  }

  const handleOpen = () => {
    setOpen(true)
  }

  const handleClose = () => {
    setOpen(false)
  }

  return (
    <div className={styles.container}>
      <Link to="/dashboard">
        <div className={styles.screenName}>  &lt; {activeScreen.display_name}</div>
      </Link>
      <div className={styles.screenDetail}>  {pknum} </div>

      <div className={styles.screenShot} onClick={()=> console.log(thumb)}>
        <img src={"data:image/png;base64," + thumb.screenshot} alt='Screenshot of railpoint' className={styles.smallThumbnail} onClick={handleOpen}/>
        <div className={styles.searchIcon}>
        </div>
        <div className={styles.refreshIcon}>
        </div>
      </div>
      <div className={styles.iconContainer}>
        <IconButton onClick={() => handleReload(pknum)}>
          <ArrowDropDownCircleIcon />
        </IconButton>
        <IconButton onClick={() => handleReboot(pknum)}>
          <PowerSettingsNewIcon />
        </IconButton>
        <IconButton onClick={() => handleTicket(pknum)}>
          <ReportIcon />
        </IconButton>
     </div>

      <div className={styles.detailContainer}>
        <div className={styles.detailTitle}> ID
          <div className={styles.detailText}>
            {activeScreen.computer_name}
          </div>
        </div>
        <div className={styles.detailTitle}> Type
          <div className={styles.detailText}>
            {activeScreen.type ? activeScreen.type : "Not Available"}
          </div>
        </div>
      </div>

      <div className={styles.noteContainer}>
        <div className={styles.detailTitle} onClick={() => console.log(activeNotes)} >Notes</div>
        <div className={styles.noteText} onClick={()=> console.log(screenNotes)}>
          {screenNotes.length === 0 ? "No notes assigned to screen" : screenNotes }
        </div>
      </div>


      <div className={styles.buttonPosition}><NoteModal displayName={activeScreen.display_name} pk={pknum} bonus={() => dispatch(viewScreenNotes(pknum))}/></div>
      <div className={styles.bottomContainer}>
        <ScreenDetail option='Station' data={activeScreen.station ? activeScreen.station : "Not Available" } />
        <ScreenDetail option='Location' data={activeScreen.text_location ? activeScreen.text_location : "Not Available"} />
        <ScreenDetail option='Station Contact' data='Not Available' />
        <ScreenDetail option='Site Image' data='Not Available' />
        <ScreenDetail option='IP Address' data={activeScreen.ip_address ? activeScreen.ip_address : "Not Available"} />
        <ScreenDetail option='Station Map' data='Not Available' />
        <ScreenDetail option='Installed' data='Not Available' />
        <ScreenDetail option='Online Since' data='Not Available' />

      </div>
      <div>
        <Dialog
        open={open}
        maxWidth='xl'
        onClose={handleClose}>
          <img src={"data:image/png;base64," + fullScreenshot.screenshot} alt="Fullsize Screenshot"/>
        </Dialog>
      </div>

    </div>
  )
}


const ScreenDetail = ({ option, data }) => {
  return (
    <div className={styles.optionContainer}>
      <div className={styles.option}>{option}</div>
      <div className={styles.data}>{data}</div>
    </div>
  )
}

