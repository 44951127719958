import React from 'react'

// Components
import Snackbar from '@material-ui/core/Snackbar'
import IconButton from '@material-ui/core/IconButton'
import CloseIcon from '@material-ui/icons/Close'

// Redux
import { useSelector, useDispatch } from 'react-redux'
import { selectOpen, closeSnackBar, selectSnackMessage } from '../../Slices/snackbarSlice'

export default function SnackbarMessage() {
    const dispatch = useDispatch()

    const isOpen = useSelector(selectOpen)
    const snackMessage = useSelector(selectSnackMessage)

    const handleClose = () => {
        dispatch(closeSnackBar())
    }

    return (
        <div>
            <Snackbar
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'left',
                }}
                open={isOpen}
                autoHideDuration={6000}
                onClose={handleClose}
                message={snackMessage}
                action={
                    <>
                    <IconButton size="small" aria-label="close" color="inherit" onClick={handleClose}>
                        <CloseIcon fontSize="small"/>
                    </IconButton>
                    </>
                }
            />       
        </div>
    )
}

// Used for those pop up messaged