import {createAsyncThunk, createSlice } from '@reduxjs/toolkit'

export const fetchScreenShot = createAsyncThunk( 
    'screenshot/getScreenshot',
    async (pk) => {
        return fetch(
            process.env.REACT_APP_API_ROOT + `/api/v1/cms/user/control/get_screenshot/${pk}`, {
                headers: {
                    Authorization: localStorage.token
                }
            }
        ).then((res) => res.json())
    }
)

export const railpointsSlice = createSlice({
    name: 'railpoints',
    initialState: {
        loading: false,
        screenshot: null,
    },
    reducers: {
    },
    extraReducers: {
        [fetchScreenShot.pending]: (state, action) => {
            state.loading = true
        },
        [fetchScreenShot.fulfilled]: (state, action) => {
            state.loading = false
            state.screenshot = action.payload
        }
    }
})

export const selectLoading = (state) => state.railpoints.loading

export const selectScreenshot = (state) => state.railpoints.screenshot

export default railpointsSlice.reducer