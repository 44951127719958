import React from 'react'
import { useState } from "react"

// eslint-disable-next-line no-unused-vars
import { BrowserRouter as Link, NavLink } from 'react-router-dom'
import { useHistory } from "react-router-dom"
// import PropTypes from 'prop-types'

// Styles
import styles from './NavigationBar.module.scss'

// Components
// import NavItem from './NavItem'

// Icons
import DashboardIcon from '@material-ui/icons/Dashboard';
// import NotStartedOutlinedIcon from '@mui/icons-material/NotStartedOutlined';

// import DeleteIcon from '@material-ui/icons/Delete';
// import DeleteIcon from '@material-ui/icons/Delete';
// import KeyboardDoubleArrowRightIcon from '@material-ui/icons/KeyboardDoubleArrowRight';

// import PermMediaIcon from '@material-ui/icons/PermMedia';
import TheatersIcon from '@material-ui/icons/Theaters';
// import PermMediaOutlinedIcon from '@mui/icons-material/PermMediaOutlined';
import PlaylistPlayIcon from '@material-ui/icons/PlaylistPlay';

// import DoubleArrowIcon from '@mui/icons/DoubleArrow';

// import PlaylistPlayIcon from '@material-ui/icons/Analytics';
import AnalyticsIcon from '@material-ui/icons/Assessment';
import SettingsIcon from '@material-ui/icons/Settings';
import FeaturedVideoIcon from '@material-ui/icons/FeaturedVideo';
import DynamicFeedIcon from '@material-ui/icons/DynamicFeed';
// import LogoutIcon from '@material-ui/icons/Logout';
import ExitToAppIcon from '@material-ui/icons/ExitToApp';

// import ArrowForwardIosIcon from '@material-ui/icons/ArrowForwardIos';

// import DoubleArrowIcon from '@material-ui/icons/DoubleArrow';

// import FeedIcon from '@material-ui/icons/Feed';
// import FeedOutlinedIcon from '@material-ui/icons/FeedOutlined';

// Redux
import { useDispatch } from 'react-redux'
import { logoutUser } from '../../app/Screens/Login/LoginSlice'
import { emptyScreenList } from '../../app/Screens/ScreenManager/ScreenManagerSlice'

export default function NavigationBar () {
  const dispatch = useDispatch()
  const history = useHistory()

  const handleLogout = () => {
    console.log('loggin out')
    localStorage.clear();
    history.push("/")
    dispatch(emptyScreenList())
    dispatch(logoutUser())  
  }

    // const [isActive, setActive] = useState(false);
    const [isActive] = useState(false);

    // const toggleClass = () => {
    //     setActive(!isActive);
    // };  


  return (
    // <div className={isActive ? styles.navigationBarWide: styles.navigationBar}>
    <div className={`${styles.navigationBar} ${isActive ? styles.toggleWidth:null}`}>

    <div className={`${styles.navButton} ${isActive ? styles.toggleButton:null}`}>
        <div className={styles.navButtonInner}>R</div>
    </div>

    {/* <div className={styles.navDivider}>
      <div className={styles.navDividerText}>MAINT</div>
    </div> */}



    {/* So this is clearly a component */}
    <a href="/Dashboard">
        <div className={`${styles.navButton} ${isActive ? styles.toggleButton:null}`}>
            <div className={styles.navButtonInner}>
                <DashboardIcon className={styles.navIcon} />
            </div>

            {isActive && <div className={styles.navText}>Dashboard</div>}

            {/* <div className={styles.navText}>Dashboard</div> */}
        </div>
    </a>

    <a href="/feeds">
      <div className={`${styles.navButton} ${isActive ? styles.toggleButton:null}`}>
        <div className={styles.navButtonInner}>
            <DynamicFeedIcon className={styles.navIcons} />
        </div>
        {isActive && <div className={styles.navText}>Feeds</div>}
      </div>
    </a>

    {/* <div className={styles.navDivider}>
      <div className={styles.navDividerText}>MEDIA</div>
    </div> */}

    <a href="/Dashboard">
      <div className={`${styles.navButton} ${isActive ? styles.toggleButton:null}`}>
        <div className={styles.navButtonInner}>
            <TheatersIcon className={styles.navIcons} />
        </div>
        {isActive && <div className={styles.navText}>Media Manager</div>}
      </div>
    </a>

    <a href="/Dashboard">
      <div className={`${styles.navButton} ${isActive ? styles.toggleButton:null}`}>
        <div className={styles.navButtonInner}>
            <PlaylistPlayIcon className={styles.navIcons} />
        </div>
        {isActive && <div className={styles.navText}>Playlists</div>}
      </div>
    </a>

    <a href="/Dashboard">
      <div className={`${styles.navButton} ${isActive ? styles.toggleButton:null}`}>
        <div className={styles.navButtonInner}>
            <FeaturedVideoIcon className={styles.navIcons} />
        </div>
        {isActive && <div className={styles.navText}>Ads</div>}
      </div>
    </a>

    <a href="/Dashboard">
      <div className={`${styles.navButton} ${isActive ? styles.toggleButton:null}`}>
        <div className={styles.navButtonInner}>
            <AnalyticsIcon className={styles.navIcons} />
        </div>
        {isActive && <div className={styles.navText}>Analytics</div>}
      </div>
    </a>

      {/* <div className={styles.navDivider}>
        <div className={styles.navDividerText}>USER</div>
      </div> */}

    <a href="/Dashboard">
      <div className={`${styles.navButton} ${isActive ? styles.toggleButton:null}`}>
        <div className={styles.navButtonInner}>
          <SettingsIcon className={styles.navIcons} />
        </div>
        {isActive && <div className={styles.navText}>Settings</div>}
      </div>
    </a>


    <div className={`${styles.navButton} ${isActive ? styles.toggleButton:null}`} onClick={handleLogout}>
      <div className={styles.navButtonInner}>
          <ExitToAppIcon className={styles.navIcons} />
      </div>
    </div>


      {/* <NavLink to='/'>
        <div className={styles.railpoint}>RailPoint</div>
      </NavLink> */}


      {/* <Router> */}
      {/* <NavLink to='/tickets'> */}
        {/* <NavItem page='Tickets' /> */}
      {/* </NavLink> */}



      {/* <div className={styles.splitter}>Maintenance</div> */}


      
      {/* <NavLink to='/media-manager'> */}
      {/* </NavLink> */}
      {/* <NavLink to='/playlists'> */}
      {/* </NavLink> */}
      {/* <NavLink to='/ads'> */}
      {/* </NavLink> */}


      {/* <NavLink to='/dashboard'>
        <NavItem icon={<DashboardIcon/>} page='Dashboard' />
      </NavLink>
      <NavLink to='/feeds'>
        <NavItem icon={<DynamicFeedIcon/>} page='Feeds' />
      </NavLink> */}


      {/* <MenuList> */}
        {/* <MenuItem>
          <ListItemIcon>
            <DashboardIcon/>
          </ListItemIcon>
          <ListItemText> Dashboard </ListItemText>
        </MenuItem> */}
      {/* </MenuList> */}
      {/* <NavLink to='/screensavers'>
        <NavItem page='Screensavers' />
      </NavLink> */}
      
      {/* <div className={styles.splitter}>Media Management</div>
      <NavItem disabled={true} icon={<PermMediaIcon/>} page='Media Manager' />
      <NavItem disabled={true} icon={<PlaylistPlayIcon/>} page='Playlists' />
      <NavItem disabled={true} icon={<FeaturedVideoIcon/>} page='Ads' />
      <NavItem disabled={true} icon={<AnalyticsIcon/>} page='Analytics' /> */}
      

      {/* <NavLink to='/screen-manager'>
        <NavItem page='Screen Manager' />
      </NavLink> */}
      {/* <NavLink to='/analytics'> */}
      {/* </NavLink> */}
      {/* <NavLink to='/settings'> */}



        {/* <NavItem disabled={true} icon={<SettingsIcon/>} page='Settings' /> */}



      {/* </NavLink> */}
      {/* </Router> */}
      <div className={styles.logoWrapper}>
          <div className={styles.railpointLogo}></div>
      </div>

      {/* <div className={styles.navButtonExpand} onClick={() => toggleClass()}>
        <div className={styles.navExpandButtonInner}>
            <DoubleArrowIcon className={styles.navIcons} />
        </div>
      </div> */}

      {/* <div className={styles.logoutContainer}>
        <NavItem onClick={handleLogout} page='Logout'/>
      </div> */}
    </div>
  )
}

// NavigationBar.propTypes = {

// }x
