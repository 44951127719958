import React from 'react'

// Styles
import styles from './Card.module.scss'

export default function Card ({ title, children }) {
  return (
    <div className={styles.card}>
      <div className={styles.cardTitle}>
        {title}
      </div>
      {children}
    </div>
  )
}


// Note: Generic Card component to be used on the dashboard