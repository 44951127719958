import React from 'react'
// import { DataGrid }'; from '@material-ui/data-grid';
import MUIDataTable from 'mui-datatables'
import { DateTime } from "luxon"


// Styles
import styles from './ScreenManagerTable.module.scss'

export default function ScreenManagerTable ({ data }) {
  const columns = [
    {
      name: 'display_name',
      label: 'Screen ID'
    },
    {
      name: 'screenshot_timestamp',
      label: 'Status',
      options: {
        customBodyRender: (value, tableMeta, updateValue) => (
          <>
            {value === null && <> <span className={styles.offline} /> Offline </>}
            {value !== null && <> <span className={styles.online} /> Online</>}
            {/* {value} */}
          </>

        )
      }
    },
    {
      name: 'screenshot_timestamp',
      label: 'Last Seen',
      options: {
        customBodyRender: (value, tableMeta, updateValue) => (
          <>
            {DateTime.fromISO(value).toLocaleString({
                weekday: "short",
                month: "short",
                day: "2-digit",
                hour: "2-digit",
                minute: "2-digit",
              })}
          </>

        )
      }    
    },
    {
      name: 'station',
      label: 'Station'
    },
  ]
  return (
    <div>
      <MUIDataTable columns={columns} data={data} rowsPerPage={50} />
    </div>
  )
}
