import React from 'react'

// Style
import styles from './FeedStatus.module.scss'


export default function FeedStatus({status, feedName}) {
    return (
        <div className={styles.feedContainer}>
            {status === false ? 
            <span className={styles.redIcon}> ● </span>
            :
            <span className={styles.greenIcon}> ● </span>
            }
           {feedName}
        </div>
    )
}
