// import { StylesProvider } from '@material-ui/core'
import React, { useState, useEffect, useCallback } from "react"
import { useDispatch, useSelector } from "react-redux"

// Components
import CheckBox from "@material-ui/core/Checkbox"
import FormControl from "@material-ui/core/FormControl"
import FormControlLabel from "@material-ui/core/FormControlLabel"
import FormGroup from "@material-ui/core/FormGroup"
import Radio from '@material-ui/core/Radio'
import RadioGroup from '@material-ui/core/RadioGroup'
// import Button from '@material-ui/core/Button'
// Styles
import styles from "./ScreenManagerFilter.module.scss"

// Redux
import {
  setFilteredData,
  selectScreenList,
  enableFilterState,
  selectFilterState,
  disableFilterState,
  setSortOption,
  selectSortOption,
  selectLoaded,
  setFilteredViaState,
  selectFilteredVia,
  setNumOfActiveFilters,
  setscreenListFilteredByOperator
  // enableFilterState,
} from "../../Screens/ScreenManager/ScreenManagerSlice"

// Todo : Refactor once happy with functionality 
// Todo : Stop applying filters on render
export default function ScreenManagerFilter() {
  const dispatch = useDispatch()

  const [filterStationList, setFilterStationList] = useState([])
  const [checkedStationList, setCheckedStationList] = useState({})
  const [filterOperatorList, setFilterOperatorList] = useState([])
  const [checkedOperatorList, setCheckedOperatorList] = useState({})

  const screenList = useSelector(selectScreenList)
  const sortOption = useSelector(selectSortOption)
  const filterState = useSelector(selectFilterState)
  const loaded = useSelector(selectLoaded)
  const filteredVia = useSelector(selectFilteredVia)
  // const loaded = useSelector(selectLoaded)

  // const handleStationFilterChange = (event) => {
  //   setLnerStations({
  //     ...lnerStations,
  //     [event.target.name]: event.target.checked,
  //   })
  // }

  // Refactor into one function
  const handleCheckChange = (event) => {
    setCheckedStationList({
      ...checkedStationList,
      [event.target.name]: event.target.checked
    })
    console.log(checkedStationList)
  }

  const handleOperatorChange = (event) => {
    setCheckedOperatorList({
      ...checkedOperatorList,
      [event.target.name]: event.target.checked
    })
  }

  // const handleFilters = () => {
  //   dispatch(applyFilter())

  //   let newList = []
  //   let filterCount = 0

  //   if (lnerStations.online === true) {
  //     newList.push(screenList.filter((x) => x.screenshot_timestamp !== null))
  //     filterCount++
  //   }

  //   if (lnerStations.offline === true) {
  //     newList.push(screenList.filter((x) => x.screenshot_timestamp === null))
  //     filterCount++
  //   }

  //   if (lnerStations.fiveMins === true) {
  //     newList.push(
  //       screenList.filter(
  //         (x) =>
  //           new Date() - new Date(x.screenshot_timestamp) >= 5 * 60 * 1000 &&
  //           new Date() - new Date(x.screenshot_timestamp) < 30 * 60 * 1000
  //       )
  //     )
  //     filterCount++
  //   }

  //   if (lnerStations.thirtyMins === true) {
  //     newList.push(
  //       screenList.filter(
  //         (x) =>
  //           new Date() - new Date(x.screenshot_timestamp) >= 30 * 60 * 1000 &&
  //           x.screenshot_timestamp !== null
  //       )
  //     )
  //   }

  //   if (lnerStations.berwickUponTweed === true) {
  //     newList.push(
  //       screenList.filter(
  //         (x) => x.station.toLowerCase() === "berwick-upon-tweed"
  //       )
  //     )
  //     filterCount++
  //   }
  //   //  console.log(newList.flat())
  //   console.log(filterCount)
  //   dispatch(enableFilterState())
  //   dispatch(setFilteredData(newList.flat()))
  // }

  // const clearFilter = () => {
  //   setLnerStations({
  //     online: false,
  //     offline: false,
  //     fiveMins: false,
  //     thirtyMins: false
  //   })
  //   console.log('clearing')

  //   initialiseFilterValues()
  //   dispatch(disableFilterState())
  // }x

  const setActiveFilterNumbers = () => {
    console.log('Setting active num')
    // Creates number of active filters for the badge
    // Converts objects to arrays containing their values
    let stationValueArray = Object.values(checkedStationList)
    let operatorValueArray = Object.values(checkedOperatorList)
    // sets number of active filters to the length filtered array of them both
    dispatch(setNumOfActiveFilters(stationValueArray.concat(operatorValueArray).filter(x => x === true).length))
  }

  const handleSortChange = (e) => {
    dispatch(setSortOption(e.target.value))
  }

  // const initialiseFilterValues = () => {
  // // todo, get rid of this bullshit
  // // Create an object from the unique array and setting them all to false
  // // so we can use them in our checkboxes
  // let uniqueStationObject = Object.fromEntries(
  //   filterStationList.map(k => ({
  //     [k]: false
  //   }))    
  // )
  
  // console.log('clear x2')
  // console.log(filterStationList)
  // console.log(uniqueStationObject)
  // console.log(checkedStationList)
  // // Pass the new object to state so we can toggle the checkboxes
  // setCheckedStationList(uniqueStationObject)

  //   // creating an object from our array again
  //   let uniqueOperatorsObject = Object.fromEntries(
  //     filterOperatorList.map(k => ({
  //       [k]: false
  //     }))
  //   )
  //   // Passing it to state
  //   setCheckedOperatorList(uniqueOperatorsObject)

  // }

  useEffect(() => {
  const organiseFilterList = () => {
  // Creates a list of all the unique stations from the api
  let uniqueStations = [...new Set(screenList.map(x => x.station))]
  // Sort them alphabetically so we can display them in alphabetical order
  uniqueStations.sort()
  
  // Creates a list of all the unique objects from the api
  let uniqueOperators = [...new Set(screenList.map(x => x.operator))]
  // Sort them alphabetically
  uniqueOperators.sort()

  // Store ours arrays into state
  setFilterStationList(uniqueStations)
  setFilterOperatorList(uniqueOperators)

  // Create an object from the unique array and setting them all to false
  // so we can use them in our checkboxes
  // todo: perhaps revert below
  // let uniqueStationObject = Object.fromEntries(
  //   uniqueStations.map(k => ({
  //     [k]: false
  //   }))    
  // )
  let uniqueStationsObject = uniqueStations.reduce((o, key) => ({ ...o, [key]: false}), {})
  
  // Pass the new object to state so we can toggle the checkboxes
  setCheckedStationList(uniqueStationsObject)

  // handleClear
  // setAllFalseStations(uniqueStationObject)
  
  
  // creating an object from our array again
  // todo: perhaps revert below
  // let uniqueOperatorsObject = Object.fromEntries(
  //   uniqueOperators.map(k => ({
  //     [k]: false
  //   }))
  // )
  let uniqueOperatorsObject = uniqueOperators.reduce((o, key) => ({ ...o, [key]: false}), {})

  // Passing it to state
  setCheckedOperatorList(uniqueOperatorsObject)

  // handleClear
  // setAllFalseOperators(uniqueOperatorsObject)
  }
  organiseFilterList()
  // todo: fix exhaustive-deps
  // eslint-disable-next-line 
  }, [loaded])

  const handleClear = () => {
    dispatch(disableFilterState())
    const stationObj = filterStationList.reduce((o, key) => ({ ...o, [key]: false}), {})
    const operatorObj = filterOperatorList.reduce((o, key) => ({ ...o, [key]: false}), {})
  
    setCheckedOperatorList(operatorObj)
    setCheckedStationList(stationObj)

    dispatch(setFilteredViaState({
      ...filteredVia,
      operator: false,
      station: false
    }))


    dispatch(setNumOfActiveFilters(0))
    // initialiseFilterValues()
  }

  

  const stationsAvailable = filterStationList.map((x) => {
    return (
      <>
        <FormControlLabel key={x} control={<CheckBox name={x} onChange={handleCheckChange} checked={checkedStationList[x] || false} />} label={x}/>
      </>
    )
  })

  const operatorsAvailable = filterOperatorList.map((x) => {
    return (
      <>
        <FormControlLabel key={x} control={<CheckBox name={x} onChange={handleOperatorChange} checked={checkedOperatorList[x] || false} />} label={x}/>
      </>
    )
  })

  const filterByStationOrOperator = useCallback((filterCriteria, type) => {
    // Criteria must be an array
    let result = []

    filterCriteria.forEach(x => {
      result.push(screenList.filter(y => 
        (type === 'station' ? y.station : y.operator) === x))  
    })

    return result.flat()

  },[screenList])


  const applyStationFilters = () => {
    // checkedStationList
    console.log('applying station filters')
    let selectedStationsArray = Object.keys(checkedStationList).filter(k => checkedStationList[k])
    let selectedOperatorsArray = Object.keys(checkedOperatorList).filter(k => checkedOperatorList[k])
    console.log(selectedStationsArray.length, '+', selectedOperatorsArray.length)

    if (selectedStationsArray.length > 0 && selectedOperatorsArray.length > 0)
    {
      console.log('both are selected')
      const stationResults = filterByStationOrOperator(selectedStationsArray, 'station')
      console.log('Station Data ==', stationResults)
      const operatorResults = filterByStationOrOperator(selectedOperatorsArray, 'operator', )

      const filteredByBoth = filterByStationAndOperator(stationResults, selectedOperatorsArray)
      console.log('state & op', operatorResults)
      dispatch(setFilteredData(filteredByBoth))
      dispatch(setFilteredViaState({...filteredVia,
        operator: true,
        station: true
      }))
    }
    else if (selectedStationsArray.length > 0 )
    {
      const stationResults = filterByStationOrOperator(selectedStationsArray, 'station')
      console.log('filter by stations', stationResults)
      dispatch(enableFilterState())
      dispatch(setFilteredData(stationResults))
      dispatch(setFilteredViaState({...filteredVia,
        operator: false,
        station: true
      }))
    }
    else if (selectedOperatorsArray.length > 0)
    {
      const operatorResults = filterByStationOrOperator(selectedOperatorsArray,'operator')
      console.log(operatorResults, 'ops')
      dispatch(enableFilterState())
      dispatch(setFilteredData(operatorResults))
      dispatch(setFilteredViaState({...filteredVia,
        operator: true,
        station: false
      }))
    }
    dispatch(enableFilterState())
    dispatch(setscreenListFilteredByOperator({type: 'Operator',data:[0,1,2,3,4,5,6,7,9]}))
    setActiveFilterNumbers()
  }

  const filterByStationAndOperator = (filteredStations, operatorToFilterBy ) => {
    let result = []

    operatorToFilterBy.forEach(x => {
      result.push(filteredStations.filter(y => y.operator === x))
    })

    return result.flat()
    // const filteredStations = filterByStationOrOperator(,)
  }

  useEffect(() => {
    if (filterState === true)
    {
      applyStationFilters()
    }
    //todo: Rethink this due to exhaustive-deps
    // eslint-disable-next-line
  },[screenList, filterState])

//   useEffect(() => {
//     if (screenList !== [])
//     {
//       applyStationFilters()
//     }
// }, [screenList, applyStationFilters ])

// const generateObjs = () => {
//   console.log('generating objies')
//   // console.log(filterStationList)

//   const stationObj = filterStationList.reduce((o, key) => ({ ...o, [key]: false}), {})
//   const operatorObj = filterOperatorList.reduce((o, key) => ({ ...o, [key]: false}), {})

//   setCheckedOperatorList(operatorObj)
//   setCheckedStationList(stationObj)
  
//   console.log(stationObj)
//   console.log(operatorObj)
//   // console.log(filterOperatorList)
// }

  return (
    <div className={styles.filter}>
      <div className={styles.filterTitle}>Filter Options</div>
      <div className={styles.filterContent}>
        <div className={styles.formContainer}>
          {/* <TextField
            type="input"
            onChange={handleSearchChange}
            onKeyDown={(e) => handleEnter(e)}
          /> */}
          {/* <button onClick={handleSearch}> Search </button> */}

          {/* Boarding up window */}
          {/* <p> Status </p>
          <FormGroup>
            <FormControlLabel
              control={
                <CheckBox
                  label="Search"
                  checked={lnerStations.online}
                  onChange={handleStationFilterChange}
                  name="online"
                />
              }
              label="Active"
            />
            <FormControlLabel
              control={
                <CheckBox
                  checked={lnerStations.offline}
                  onChange={handleStationFilterChange}
                  name="offline"
                />
              }
              label="Inactive"
            />
            <FormControlLabel
              control={
                <CheckBox
                  name="fiveMins"
                  onChange={handleStationFilterChange}
                  checked={lnerStations.fiveMins}
                />
              }
              label="Potentially offline "
            />

            <FormControlLabel
              control={
                <CheckBox
                  name="thirtyMins"
                  onChange={handleStationFilterChange}
                  checked={lnerStations.thirtyMins}
                />
              }
              label="Offline"
            />
          </FormGroup> */}
        </div>
        <p className={styles.filterName}>Stations</p>
        <div className={styles.operatorContainer}>
            {stationsAvailable.length > 0 ? 
          <FormGroup>
            {stationsAvailable} 
          </FormGroup>
            : 'generating filter options' }
        </div>
        <p className={styles.filterName}>Operators</p>
        <div className={styles.operatorContainer}>
            {operatorsAvailable.length > 0 ? 
          <FormGroup>
            {operatorsAvailable} 
          </FormGroup>
            : 'generating filters options'}
        </div>
        {/* Sorting Options */}
        <p className={styles.filterName}> Sort by </p>
        <div className={styles.formContainer}>
          <FormControl component="fieldset">
          <RadioGroup value={sortOption} onChange={handleSortChange}>
          <FormControlLabel value='alphabeticalAsc' control={<Radio/>} label="Alphabetical: A-Z"/>
          <FormControlLabel value='alphabeticalDesc' control={<Radio/>} label="Alphabetical:  Z-A"/>
          <FormControlLabel value='oldestFirst' control={<Radio/>} label="Oldest First"/>
          <FormControlLabel value='newestFirst' control={<Radio/>} label="Newest First"/>
          </RadioGroup>
          </FormControl>
        </div>
        <div className={styles.buttonContainer}>
          <button onClick={applyStationFilters}> Apply </button>
          <button onClick={handleClear}> Clear </button>
        </div>
      </div>
    </div>
  )
}