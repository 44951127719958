
// Within 10 minutes is considered fine
const onlineCriteria = 10 * 60 * 1000


// const potentiallyOfflineScreen = 30 * 60 * 1000

// const offlineCriteria = 60 * 60 * 1000


export const isScreenPotentiallyOffline = (timestamp) => {
  if (
    new Date() - new Date(timestamp) >= 10 * 60 * 1000 &&
    new Date() - new Date(timestamp) < 60 * 60 * 1000
  ) {
    console.log("is true")
    return true
  } else {
    console.log("is false")
    return false
  }
}

export const isScreenOffline = (timestamp) => {
  if (new Date() - new Date(timestamp) >= 60 * 60 * 1000) {
    return true
  }
}

export const determineConnectivity = (timestamp) => {
  if (timestamp === null) {
    return 'inactive'
    }
  if (new Date() - new Date(timestamp) <= onlineCriteria) {
    return 'online'
  }
  if (new Date() - new Date(timestamp) > onlineCriteria &&
  new Date() - new Date(timestamp) < 60 * 60 * 1000)
  {
      return 'potentiallyOffline'
  }
  if (new Date() - new Date(timestamp) >= 60 * 60 * 1000) {
    return 'offline'
  }
}

export const returnAllOnlineScreens = (screens) => {
  return screens.filter((x) => 
    new Date() - new Date(x.screenshot_timestamp) < onlineCriteria)
}