// // If your reducer isn't defined you probably forgot to export it as an aciton
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'

// Here I'm using an async thunk to send an API request
export const fetchAds = createAsyncThunk('media/fetchAds', async (data) => {
  return fetch(process.env.REACT_APP_API_ROOT + '/api/v1/cms/user/ads/', {
    headers: {
      Authorization: localStorage.token
    }
  }).then((res) => res.json())
})

//
export const adsSlice = createSlice({
  // Name used in aciton types
  name: 'ads',
  // Initial state of the reducerr
  initialState: {
    ads: [],
    loading: false
  },
  reducers: {},
  // I handle the response using the extra reducers,
  extraReducers: {
    [fetchAds.pending]: (state) => {
      state.loading = true
    },
    [fetchAds.fulfilled]: (state, action) => {
      state.loading = false
      state.ads = action.payload
    },
    [fetchAds.rejected]: (state) => {
      state.loading = false
    }

  }
})

export const selectAds = (state) => state.ads.ads

export default adsSlice.reducer

// Exporting reducer to import it in store.js
