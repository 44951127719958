import React, { useEffect, useState } from "react"
import { useHistory } from "react-router-dom"

// Components
import IconButton from "@material-ui/core/IconButton"
// import Button from "@material-ui/core/Button"
import TextField from "@material-ui/core/TextField"
import Dialog from "@material-ui/core/Dialog"
import DialogActions from "@material-ui/core/DialogActions"
import DialogContent from "@material-ui/core/DialogContent"
import DialogContentText from "@material-ui/core/DialogContentText"
import DialogTitle from "@material-ui/core/DialogTitle"
import Snackbar from '../Snackbar/Snackbar'

// Styles
import styles from "./ScreenManagerMenu.module.scss"

// Icons
import ReloadIcon from "@material-ui/icons/PlayCircleFilled"
import RebootIcon from "@material-ui/icons/PowerSettingsNew"
// import GroupIcon from "@material-ui/icons/GroupWork"
import ReportIcon from "@material-ui/icons/Report"
import EditIcon from "@material-ui/icons/Edit"
// import CancelIcon from '@material-ui/icons/Cancel'
import InfoIcon from "@material-ui/icons/Info"
import ClearIcon from "@material-ui/icons/Clear"

// Redux
import { useDispatch, useSelector } from "react-redux"
import { clearSelected, selectSelectedScreens, reloadScreen, rebootScreen} from "../../Screens/ScreenManager/ScreenManagerSlice"
import { openSnackBar, setSnackMessage } from "../../Slices/snackbarSlice"

export default function ScreenManagerMenu({
  // selectedScreens,
  onNoteClick,
  onReportClick,
  disabled,
  pkList,
  // addScreenNote
}) {

  // States
  let history = useHistory()
  const dispatch = useDispatch()

  const [iconState, setIconState] = useState(false)
  const [noteText, setNoteText] = useState("")
  const [open, setOpen] = useState(false)

  // Selectors
  const selectedScreens = useSelector(selectSelectedScreens)

  // Functions
  const handleClickOpen = () => {
    setOpen(true)
  }

  const handleClose = () => {
    setOpen(false)
  }

  const handleTextChange = (e) => {
    setNoteText(e.target.value)
  }

  const handleAddNote = () => {

    let formdata = new FormData()
    formdata.append("note", noteText)

    let myHeaders = new Headers()
    myHeaders.append("Authorization", localStorage.token)

    let requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: formdata,
      redirect: "follow",
    }
 
    selectedScreens.forEach((pk) => {
       fetch(
        process.env.REACT_APP_API_ROOT + "/api/v1/cms/user/control/add-screen-note/" + pk,
      requestOptions
    ).then((res) => {
      
      // res.json()
      console.log(res)
      console.log(res.statusText)
      dispatch(setSnackMessage('Note ' + res.statusText))
      dispatch(openSnackBar())
      setNoteText("")
    })
    .catch(err => {
      dispatch(setSnackMessage('Note ' + JSON.stringify(err)))
      dispatch(openSnackBar())
    })

      // console.log('Hi', x )
    })

    handleClose()
  }

  useEffect(() => {
    console.log('use effect', selectedScreens, selectedScreens.length)
    if (selectedScreens.length === 1) {
      setIconState(false)
    } else {
      setIconState(true)
    }
  }, [selectedScreens])

  function handleClick() {
    history.push("/screen/" + pkList[0])
    // console.log(selectedScreens)
    console.log(pkList[0])
  }

  const handleReload = () => {
    console.log(selectedScreens)
    selectedScreens.forEach(x => {
      dispatch(reloadScreen(x))
      // dispatch(fetchThumbnail(x))
      // console.log(x)
    })
    dispatch(clearSelected())
    dispatch(setSnackMessage('Reload request sent'))
    dispatch(openSnackBar())
    
  }
  
  const handleReboot = () => { 
    console.log(selectedScreens)
    selectedScreens.forEach(x => {
      dispatch(rebootScreen(x))

    })
    dispatch(setSnackMessage('Reboot request sent'))
    dispatch(openSnackBar())
    dispatch(clearSelected())
    
  }

  return (
    <div className={styles.menu}>
      <div className={styles.selectedNumber}>
        <div className={styles.numPosition}>{selectedScreens.length}</div>
      </div>
      <div className={styles.iconContainer}>
        <IconButton onClick={handleReload}>
          <ReloadIcon />
        </IconButton>
        <div className={styles.iconText}>Reload</div>
      </div>
      <div className={styles.iconContainer}>
        <IconButton onClick={handleReboot}>
          <RebootIcon />
        </IconButton>
        <div className={styles.iconText}>Reboot</div>
      </div>
      <div className={styles.iconContainer}>
        <IconButton onClick={handleClickOpen}>
          <ReportIcon  />
        </IconButton>
        <div className={styles.iconText}>Raise Ticket</div>
      </div>
      <div className={styles.iconContainer}>
        <IconButton disableFocusRipple={true} onClick={handleClickOpen}>
          <EditIcon />
        </IconButton>
        <div className={styles.iconText}>Add Note</div>
      </div>
      <div className={styles.iconContainer}>
        <IconButton disabled={iconState} onClick={handleClick}>
          <InfoIcon style={{ fontSize: 24 }} />
        </IconButton>
        <div className={styles.iconText}>info</div>
      </div>
      <div className={styles.iconContainer}>
        <IconButton onClick={() => dispatch(clearSelected())}>
          <ClearIcon style={{ fontSize: 28 }} />
        </IconButton>
        <div className={styles.iconText}>Clear Selected</div>
        <Dialog
          open={open}
          onClose={handleClose}
          aria-labelledby="form-dialog-title"
        >
          <DialogTitle id="form-dialog-title">
            Add notes to screens
          </DialogTitle>
          <DialogContent>
            <DialogContentText>
            </DialogContentText>
            <TextField
                autoFocus
                margin="dense"
                id="note"
                label="Note"
                type="text"
                fullWidth
                multiline
                rowsMax={10}
                value={noteText}
                onChange={(e) => handleTextChange(e)}
              />
          </DialogContent>
          <DialogActions>
            <button onClick={handleClose} color="primary">
              Cancel
            </button>
            <button onClick={handleAddNote} color="primary">
              Add Note
            </button>
          </DialogActions>
        </Dialog>
      </div>
      <Snackbar/>
    </div>
  )
}
