import { configureStore } from '@reduxjs/toolkit'
import loginReducer from './Screens/Login/LoginSlice'
import screenManagerReducer from './Screens/ScreenManager/ScreenManagerSlice'
import mediaManagerReducer from './Screens/MediaManager/MediaManagerSlice'
import adsReducer from './Screens/Ads/AdsSlics'
import settingsReducer from './Screens/Settings/SettingsSlice'
//todo, slice?? hello???
import screenDetailsReducer from './Screens/ScreenDetails/ScreenDetailsSlice'
import railpointssReducer from './Components/RailPoints/RailpointsSlice'
import snackbarReducer from './Slices/snackbarSlice'

export default configureStore({
  reducer: {
    login: loginReducer,
    screenManager: screenManagerReducer,
    mediaManager: mediaManagerReducer,
    ads: adsReducer,
    settings: settingsReducer,
    screenDetails: screenDetailsReducer,
    railpoints: railpointssReducer,
    snackbar: snackbarReducer,
  }
})
