import React, { useState, useEffect } from "react"
import PropTypes from "prop-types"
import { useSelector, useDispatch } from "react-redux"
import { DateTime } from "luxon"
// Components

import Modal from "@material-ui/core/Modal"
import RailpointDetails from "../RailpointDetails/RailpointDetails"

// Styles
import styles from "./Railpoints.module.scss"

// Icons
import ErrorIcon from "@material-ui/icons/Error"
import NoScreenShot from "../../../icons/NoScreenShot.svg"
import PowerOff from "../../../icons/PowerOff.svg"
import QueryBuilderIcon from "@material-ui/icons/QueryBuilderTwoTone"

// Utils
import { determineConnectivity } from "../../../utils"

// Redux
import {
  addScreen,
  selectSelectedScreens,
  // fetchThumbnail,
  updateSelected,
} from "../../Screens/ScreenManager/ScreenManagerSlice"


export default function RailPoints({
  screenname,
  pk,
  computerName,
  description,
  station,
  operator,
  text_location,
  timestamp,
}) {
  const dispatch = useDispatch()
  const [selected, setselected] = useState(false)
  // eslint-disable-next-line no-unused-vars
  const selectedScreens = useSelector(selectSelectedScreens)
  const [thumb, setThumb] = useState("")
  // eslint-disable-next-line
  const [fullScreen, setFullScreen] = useState("")
  const [open, setOpen] = useState(false)

  const [lastSeen, setLastSeen] = useState(timestamp)
  const [relativeTime, setRelativeTime] = useState("")
  const [status, setStatus] = useState('')

  useEffect(() => {
    setStatus(determineConnectivity(timestamp))
  }, [timestamp])
 
  
  const checkSelected = () => {
    if (selectedScreens.includes(pk) === true) {
      setselected(true)
    } else {
      setselected(false)
    }
  }

  const grabThumbnail = () => {
    fetch(
      process.env.REACT_APP_API_ROOT +
        '/api/v1/cms/user/control/get_screenshot/' + pk ,
      {
        headers: {
          Authorization: localStorage.token,
        },
      }
    )
      .then((res) => res.json())
      .then((res) => {
        setThumb(res)
      })
      .catch((error) => {
      })
  }

  useEffect(() => {
    checkSelected()
    // eslint-disable-next-line
  }, [selectedScreens])

  const grabFullScreen = () => {
    fetch(
      process.env.REACT_APP_API_ROOT +
        `/api/v1/cms/user/control/get_screenshot/${pk}?full_screenshot=True'`,
      {
        headers: {
          Authorization: localStorage.token,
        },
      }
    )
      .then((res) => res.json())
      .then((res) => {
        setFullScreen(res)
      })
      .catch((error) => {
        // setLoading(false)
      })
  }

  // Grab Thumbnail everytime timestamp changes
  useEffect(() => {
    timestamp !== null && grabThumbnail()
    setLastSeen(timestamp)
    // eslint-disable-next-line
  }, [timestamp])

  useEffect(() => {
    checkSelected()
    // eslint-disable-next-line
    setLastSeen(timestamp)
    // eslint-disable-next-line
  }, [])
  

  // Seconds Timer
  useEffect(() => {
    if (new Date() - new Date(lastSeen) < 1 * 60 * 1000) {
      const interval = setInterval(() => {
        setRelativeTime(DateTime.fromISO(lastSeen).toRelative())
      }, 1000)
      return () => clearInterval(interval)
    } else {
      setRelativeTime(DateTime.fromISO(lastSeen).toRelative())
    }
  }, [lastSeen])

  // Toggle Logic
  const handleToggle = (value) => {
    let newArray = [...selectedScreens]
    let index = selectedScreens.indexOf(value)

    if (index === -1) {
      dispatch(addScreen(pk))
    } else {
      newArray.splice(index, 1)
      dispatch(updateSelected(newArray))
    }
    setselected(!selected)
  }

  const handleOpen = () => {
    grabFullScreen()
    setOpen(true)
  }

  const handleClose = () => {
    setOpen(false)
  }

  return (
    <>
    <div className={styles.railpointContainer}>
    <div className={styles[status]}>
        <div
          className={
            selected === true
              ? styles.railpointsSelected
              : thumb.screenshot
              ? styles.railpointsWithImage
              : styles.railpoints
          }
        >
          <div className={styles.iconPosition}>
            {/* If screenshot_timestamp is null */}
            {timestamp === null && <ErrorIcon style={{ fill: "#FF3A20" }} />}
            {/* If the screen hasn't been seen for more than 30 mins */}
            {timestamp !== null &&
              new Date() - new Date(timestamp) > 60 * 60 * 1000 && (
                <QueryBuilderIcon style={{ fill: "#FF3A20" }} />
              )}
            {timestamp !== null &&
              new Date() - new Date(timestamp) > 5 * 60 * 1000 &&
              new Date() - new Date(timestamp) < 30 * 60 * 1000 && (
  
                <QueryBuilderIcon style={{ fill: "#FE9000" }} />
              )}
          </div>
              {/* Display the screenshot if it exists  */}
          {
            thumb.screenshot ? (
              <img
                onClick={handleOpen}
                className={styles.screenshot}
                src={"data:image/png;base64," + thumb.screenshot}
                alt={pk}
              />
            ) : (
                <img
                onClick={handleOpen}
                className={styles.noScreenShot}
                src={timestamp ? NoScreenShot : PowerOff}
                alt={"Screen Not Available"}
              />
            )            
          }
          </div>
        </div>
        <div
          className={selected === true ? styles.nameSelected : styles.name}
          onClick={() => handleToggle(pk)}
        >
          {screenname}
          <br />
          <span> {relativeTime} </span>
        </div>
      </div>
      <Modal open={open} onClose={handleClose}>
        <>
          <RailpointDetails
            displayName={screenname}
            pk={pk}
            image={thumb.screenshot}
            description={description}
            computerName={computerName}
            timestamp={timestamp}
            station={station}
            operator={operator}
          />
        </>
      </Modal>
    </>
  )
}

RailPoints.propTypes = {
  screenname: PropTypes.string.isRequired,
  pk: PropTypes.number.isRequired,
  computerName: PropTypes.string.isRequired,
  timestamp: PropTypes.string,
  description: PropTypes.string,
  operator_code: PropTypes.string,
  operator: PropTypes.string,
}
